import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory, useParams } from 'react-router-dom'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Props } from '../../store/types.d'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Dictionary } from '../../store/types.d'
import classNames from 'classnames'
import InputField from '../commonComponent/InputFieldComponent'
import { makeValueLabelList } from '../commonComponent/commonFuction'

const AddUpdateTemplateSpecificPDFStylePage: React.FC<Props> = ({ rootStore }) => {
    const { handleSubmit, register, control, setValue, errors } = useForm()

    const { templateSpecificPDFStyleStore, allStatesStore, allTemplatesStore, setTitle } = rootStore
    const { statesWithValueLabel } = allStatesStore
    const { templateValueLabel } = allTemplatesStore
    
    // const { isApiError, isLoading, apiErrorMessage, stateSpecificHCTempDetails, setStateSpecificHCTempDetails, addUpdateSingleStateSpecificHCTemp, resetStateSpecificHCTempDetails, setError, templateListForEmail } = templateSpecificPDFStyleStore
    const { isApiError, isLoading, apiErrorMessage, addUpdateTemplateSpecificPDFStyle, templateSpecificPDFStyleDetails, setTemplateSpecificPDFStyleDetails, resetTemplateSpecificPDFStyleDetails } = templateSpecificPDFStyleStore
    const [isAllState, setIsAllState] = useState(true)
    const [ isActive, setIsActive ] = useState('true')
    
    // const [selectedTemplate, setSelectTemplate] = useState<Array<ValueLabel>>()

    
    let isUpdateMode = false
    const history = useHistory()
    const { styleId } = useParams<{ styleId: string}>()

    if (styleId) {
        isUpdateMode = true

    }    

    const handleIsAllState = (e: any) => {
        const value = e.target.checked
        setIsAllState(value)
        if (value) {
            setValue('states', [])
        }
    }

    useEffect(() => {
        if (isUpdateMode) {
            if(styleId === templateSpecificPDFStyleDetails._id){
                setTitle(`${templateSpecificPDFStyleDetails.title} | Update Template Specific Additional PDF Style`)
            }
        } else {
            setTitle('Template Specific Additional PDF Style | Add')
        }
    }, [])

    useEffect(() => {

        if (styleId) {
            setTemplateSpecificPDFStyleDetails(styleId)
            if (templateSpecificPDFStyleDetails._id !== '') {
                const selectedTemplates = makeValueLabelList(templateSpecificPDFStyleDetails.templates, templateValueLabel)
                const selectedStates = makeValueLabelList(templateSpecificPDFStyleDetails.states, statesWithValueLabel)

                setValue('states', selectedStates)
                setValue('templates', selectedTemplates)
                setIsAllState(templateSpecificPDFStyleDetails.is_all_state)

                const activeStatus = (templateSpecificPDFStyleDetails.is_active ? 'true' : 'false')
                setIsActive(activeStatus)
            }

        } else if(styleId === undefined) {
            resetTemplateSpecificPDFStyleDetails()
        }
    }, [setTemplateSpecificPDFStyleDetails, styleId, resetTemplateSpecificPDFStyleDetails, templateValueLabel, statesWithValueLabel, templateSpecificPDFStyleDetails._id, templateSpecificPDFStyleDetails.is_active, templateSpecificPDFStyleDetails.weight])
    const isRequired = <span style={{color:'red'}}>*</span>

    const handleClick = () => {
        resetTemplateSpecificPDFStyleDetails()
        history.push('/template-specific-additional-pdf-styles/')
    }


    const onSubmit = async (data: Dictionary<string>) => {
        let stateIds = []
        if (!isAllState) {
            stateIds =  data.states.map((item) => item.value)
        }
   
        const tempIds = data.templates.map((item) => item.value)
        const requestData = {
            _id: isUpdateMode ? styleId : '',
            title: isUpdateMode ? templateSpecificPDFStyleDetails.title : data.title,
            states: stateIds,
            templates: tempIds,
            css: data.css,
            is_active: isUpdateMode && data.is_active === 'false' ? false : true,
            is_all_state: isAllState,
            weight: data.weight,
        }

        const response = await addUpdateTemplateSpecificPDFStyle(requestData)
        if (response.success === 1) {
            history.push('/template-specific-additional-pdf-styles/')
            setTimeout(() => {
                toast.success(response.message)
            }, 500)
        }
    }

    useEffect(() => {
        allStatesStore.getAllStates()
        allTemplatesStore.getAllTemplatesName()
    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Template Specific Additional PDF Style', to: '/template-specific-additional-pdf-styles/' },
            { name: (isUpdateMode) ? 'Update' : 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {(isUpdateMode) ? 'Update' : 'Add'} Template Specific Additional PDF Style
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}
                        <form onSubmit={handleSubmit(onSubmit)} >
                            <fieldset>
                                <InputField
                                    label='Title *'
                                    fieldName='title'
                                    register={register}
                                    errors={errors.title}
                                    placeholder='Enter Title'
                                    defaultValue={templateSpecificPDFStyleDetails.title}
                                    required={true}
                                    disabled={isUpdateMode}
                                />
                                <div className='form-group'>
                                    <label>Template *</label>
                                    <Controller
                                        control={control}
                                        name="templates"
                                        rules={{
                                            validate: (value) => (value === undefined || value === null || value.length === 0 ) ? 'Please select Template' : true
                                        }}
                                        render={({ onChange, ref, value }) => (
                                            <Select
                                                inputRef={ref}
                                                value={value}
                                                options={templateValueLabel}
                                                className={(errors.templates) ? 'is-invalid' : ''}
                                                classNamePrefix="select"
                                                onChange={val => {
                                                    onChange(val)
                                                }}
                                                placeholder='Select the Template'
                                                isSearchable
                                                menuPortalTarget={document.body}
                                                isMulti
                                            />)}
                                            
                                    />
                                    {(errors.templates) && (<div className='invalid-feedback'>Please select Template</div>)}
                                </div>
                                <div className="custom-control form-group custom-switch">
                                    <input type="checkbox" 
                                        checked={isAllState}
                                        ref={register}
                                        className="custom-control-input" name='is_all_states' 
                                        onClick={handleIsAllState} 
                                        id="allState" />
                                    <label className="custom-control-label" htmlFor='allState'>All States</label>
                                    {errors.is_all_states && (<div className='invalid-feedback'>{errors.is_all_states.message}</div>)}
                                </div>
                                <div className={classNames('form-group', { 'd-none': isAllState })}>
                                    <label>State *</label>
                                    <Controller
                                        control={control}
                                        name="states"
                                        rules={{
                                            validate: (value) => ((value === undefined || value === null || value.length === 0) && !isAllState) ? 'Please select State' : true
                                        }}
                                        render={({ onChange, ref, value }) => (
                                            <Select
                                                inputRef={ref}
                                                value={value}
                                                options={statesWithValueLabel}
                                                className={(errors.states) ? 'is-invalid' : ''}
                                                classNamePrefix="select"
                                                onChange={val => {
                                                    onChange(val)
                                                }}
                                                placeholder='Select the State'
                                                isSearchable
                                                menuPortalTarget={document.body}
                                                isMulti
                                            />)}
                                            
                                    />
                                    {(errors.states && !isAllState) && (<div className='invalid-feedback'>Please select State</div>)}
                                </div>
                                <div className='form-group'>
                                    <label>CSS *</label>
                                    <textarea
                                        ref={register({
                                            required: 'This field is required'
                                        })}
                                        name='css'
                                        defaultValue={templateSpecificPDFStyleDetails.css}
                                        style={{height:'300px'}}
                                        className={classNames('form-control', { 'is-invalid': errors.css })} 
                                    />
                                    {errors.css && (<div className='invalid-feedback'>{errors.css.message}</div>)}
                                </div>
                                <div className="form-group">
                                    <label>Weight</label>
                                    <input
                                        ref={register({
                                            required: 'This field is required'
                                        })}
                                        name='weight'
                                        type='number'
                                        min={1}
                                        className={classNames('form-control', { 'is-invalid': errors.weight })}
                                        defaultValue={templateSpecificPDFStyleDetails.weight}
                                    />
                                    {errors.weight && (<div className='invalid-feedback'>{errors.weight.message}</div>)}
                                </div>
                                {isUpdateMode && 
                                    <div className='form-group'>
                                        <label>Active Status</label>
                                        <div className='radio' >
                                            <label> <input name='is_active' type='radio' checked={isActive === 'true'} value='true' ref={register} onChange={() => setIsActive('true')} /> Active</label> &nbsp;&nbsp;
                                            <label> <input name='is_active' type='radio' checked={isActive === 'false'} value='false' ref={register} onChange={() => setIsActive('false')} /> Inactive</label>
                                        </div>
                                        {errors.is_active && (<div className='invalid-feedback'>{errors.is_active.message}</div>)}
                                    </div> 
                                }
                                <div className='form-group'>
                                    <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                        {isLoading ? 'Loading...' : 'Submit'}
                                    </button>&nbsp;&nbsp;
                                    <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddUpdateTemplateSpecificPDFStylePage)