import { action, observable } from 'mobx'
import RootStore from './Root'
import { TemplateSpecificPDFStyleInfo, Dictionary } from './types.d'
import { initialTableDataWithPaginationDetails, initialTemplateSpecificPDFStyle, } from './InitialValues'
import { addUpdateTemplateSpecificPDFStyle, getTemplateSpecificPDFStyleDetails, getTemplateSpecificPDFStyleData } from '../api/TemplateSpecificPDFStyleActions'

interface TemplateSPecificPDFStyleData {
    isFiltered: boolean
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    totalAllItems: number,
    page_items: Array<TemplateSpecificPDFStyleInfo>
}

class TemplateSpecificPDFStyleStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public templateSpecificPDFStyleDetails: TemplateSpecificPDFStyleInfo
    @observable public templateSpecificPDFStyleList: TemplateSPecificPDFStyleData
    @observable public searchItem: string
    @observable public page: number
    @observable public isLoadingInfo: boolean
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.templateSpecificPDFStyleDetails = initialTemplateSpecificPDFStyle
        this.isLoadingInfo = false
        this.templateSpecificPDFStyleList = initialTableDataWithPaginationDetails
    }

        @action resetTemplateSpecificPDFStyleDetails = (): void => {
            this.templateSpecificPDFStyleDetails = initialTemplateSpecificPDFStyle
        }

        @action setIsOpen = (isOpened: boolean): void => {
            this.isPageOpened = isOpened
        }

        @action setSearchItemField = (value: string): void => {
            this.searchItem = value
        }

        //get the list of template specific additional PDF style with details
        fetchTemplateSpecificPDFStyleData = (page: number, searchItem: string): void => {
            this.isPageOpened = true
            this.searchItem = searchItem
            this.page = page
            this.isLoading = true
            this.isApiError = false
            this.apiErrorMessage = ''

            const requestData = { page: this.page, search_item: searchItem }

            getTemplateSpecificPDFStyleData(requestData).then((response) => {
                this.isLoading = false
                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.templateSpecificPDFStyleList = response.data.data
            }).catch((error) => {

                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
            })
        }

        //add or update template specific addition PDF style using id
        addUpdateTemplateSpecificPDFStyle = (data: TemplateSpecificPDFStyleInfo): any => {
            this.isLoading = true
            this.isApiError = false
            this.apiErrorMessage = ''

            return new Promise((resolve, reject) => {
                addUpdateTemplateSpecificPDFStyle(data).then((response) => {
                    this.isLoading = false
    
                    if (response.data.success === 0) {
                        this.isApiError = true
                        this.apiErrorMessage = response.data.message
                        return
                    }
                    this.fetchTemplateSpecificPDFStyleData(this.page, this.searchItem)
                    resolve(response.data)
    
                }).catch((error) => {
                    if (error.request.status === 401) {
                        this.rootStore.authStore.setAuthToken('')
                    }
    
                    this.isLoading = false
                    this.isApiError = true
                    this.apiErrorMessage = error.message
                    reject(error.message)
                })
            })
        }


        //set the template specific addition PDF style details in templateSpecificPDFStyleDetails variable for show in update template specific addition PDF style page
        setTemplateSpecificPDFStyleDetails = (data: string): void => {
            this.isLoading = true
            this.isApiError = false
            this.apiErrorMessage = ''

            getTemplateSpecificPDFStyleDetails(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }

                this.templateSpecificPDFStyleDetails = response.data.data
            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }
                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
            })
        }

}

export default TemplateSpecificPDFStyleStore
