import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const addUpdateTemplateSpecificPDFStyle = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/template-specific-additional-pdf-styles/add-update`
    return axios.post(url, requestData)
}

export const getTemplateSpecificPDFStyleData = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/template-specific-additional-pdf-styles/`
    return axios.post(url, requestData)
}

export const getTemplateSpecificPDFStyleDetails = (styleId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/template-specific-additional-pdf-styles/template-specific-additional-pdf-style/${styleId}`
    return axios.post(url)
}