import { action, observable } from 'mobx'
import RootStore from './Root'
import { getSettings, updatePDFStyle, updateAppSetting } from '../api/SettingAction'
import { Dictionary } from './types.d'


class SettingStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public style_pdf: string
    @observable public style_docx: string
    @observable public style_preview: string
    @observable public app_version: string
    @observable public app_build: string
    @observable public testflight_app_version: string
    @observable public testflight_app_build: string
    @observable public isPageOpened: boolean


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.resetData()
    }

    @action resetData = () => {
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.style_pdf = ''
        this.style_docx = ''
        this.style_preview = ''
        this.app_version = ''
        this.app_build = ''
        this.testflight_app_version = ''
        this.testflight_app_build = ''
    }


    //get the settings of styles for documents
    fetchSettings = (): void => {
        this.isPageOpened = true
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getSettings().then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.style_pdf = response.data.data.style_pdf
            this.style_docx = response.data.data.style_docx
            this.style_preview = response.data.data.style_preview
            this.app_build = response.data.data.app_build
            this.app_version = response.data.data.app_version
            this.testflight_app_build = response.data.data.testflight_app_build
            this.testflight_app_version = response.data.data.testflight_app_version
            
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    @action changePDFStyle = (newStyle: string) => {
        this.style_pdf = newStyle
    }

    updateStylePDF = (data: string): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            updatePDFStyle({ style_pdf: data }).then((response) => {
                this.isLoading = false
                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchSettings()
                resolve('true')
            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }
                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }

    updateAppDetails = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            updateAppSetting(data).then((response) => {
                this.isLoading = false
                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchSettings()
                resolve('true')
            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }
                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }

}

export default SettingStore
