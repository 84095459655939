import { action, observable, runInAction } from 'mobx'
import RootStore from './Root'
import { getAllTemplates } from '../api/TemplateActions'
import { NameId, ValueLabel } from './types.d'

class AllTemplatesStore {
    public rootStore: RootStore
    @observable public isLoading = false
    @observable public isLoaded = false
    @observable public allTemplatesName: Array<NameId> = []
    @observable public templateValueLabel: Array<ValueLabel> = []

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action resetData = () => {
        this.isLoading = false
        this.isLoaded = false
        this.allTemplatesName = []
    }

    @action
    public getAllTemplatesName() {
        if (this.isLoaded === false) {
            this.reload()
        }
    }

    @action
    public forceReload() {
        this.reload()
    }

    @action
    public reload() {
        this.isLoading = true
        getAllTemplates().then((response) => {
            runInAction(() => {
                this.isLoading = false
                this.allTemplatesName = response.data.data
                this.templateValueLabel = response.data.data.map((list: NameId) => {
                    const data = {
                        value: list._id || '',
                        label: list.name
                    }
                    return data 
                })
                this.isLoaded = true
            })
        }).catch((error) => {
            runInAction(() => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }
                this.isLoading = false
            })
        })
    }
}

export default AllTemplatesStore
