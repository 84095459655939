import AuthStore from './Auth'
import axios from 'axios'
import NavStore from './Nav'
import AppUserStore from './AppUser'
import SuperAdminStore from './SuperAdmin'
import StateStore from './State'
import FieldStore from './Field'
import TemplateStore from './Template'
import PackageStore from './Package'
import ClientStore from './Client'
import TemplateFieldStore from './TemplateFIeld'
import PluralStore from './Plural'
import ConditionStore from './Condition'
import CommonParagraph from './CommonParagraph'
import MonacoEditorStore from './MonacoEditorStore'
import DeedResearchStore from './DeedResearch'
import MatterStore from './Matter'
import SettingStore from './Settings'
import DebugStore from './DebugStore'
import TrusteeInfoStore from './TrusteeInformation'
import PlanStore from './vision/Plan'
import ProblemQuestionStore from './vision/ProblemQuestion'
import EstateProtectPlanStore from './vision/EstateProtectPlan'
import VisionStore from './vision/Vision'
import ProbableStore from './Probable'
import InvoiceStore from './vision/Invoice'
import DeedFilingStore from './DeedFiling'
import LawmaticsStore from './Lawmatics'
import PlanPricingTemplateStore from './vision/PlanPricingTemplate'
import EngagementTemplateStore from './vision/EngagementTemplate'
import ErrorLogStore from './ErrorLog'
import ThirdPartyLogStore from './ThirdPartLog'
import TagStore from './Tag'
import EmailLogStore from './EmailLog'
import StateSpecificHCTempStore from './StateSpecificHCInfo'
import AudioStore from './vision/Audio'
import AllTemplatesStore from './AllTemplatesStore'
import AllUsersStore from './AllUsersStore'
import AllStatesStore from './AllStatesStore'
import AllFieldsStore from './AllFieldsStore'
import AllPackagesStore from './AllPackagesStore'
import AllConditionsStore from './AllConditionsStore'
import AllTagStore from './AllTagsStore'
import AllEngagementTemplateStore from './vision/AllEngagementTemplateStore'
import AllPlanPricingTemplateStore from './vision/AllPlanPricingTemplateStore'
import IpadLocalDataStore from './IpadLocalDataStore'
import ClientDraftAdjustmentStore from './ClientDraftAdjustment'
import SingleDocDeedImagesStore from './DeedImagesStore'
import OrganizationStore from './OrganizationStore'
import AddressStore from './Address'
import AllAddressStore from './AllAddressStore'
import AllOrgStore from './AllOrganizationStore'
import { action } from 'mobx'
import PackagePlanStore from './PackagePlan'
import TemplateSpecificPDFStyleStore from './TemplateSpecificPDFStyleStore'

axios.defaults.baseURL = process.env.REACT_APP_BASEURL

class RootStore {
    public authStore: AuthStore
    public navStore: NavStore
    public appUserStore: AppUserStore
    public superAdminStore: SuperAdminStore
    public stateStore: StateStore
    public fieldStore: FieldStore
    public templateStore: TemplateStore
    public packageStore: PackageStore
    public clientStore: ClientStore
    public templateFieldStore: TemplateFieldStore
    public pluralStore: PluralStore
    public conditionStore: ConditionStore
    public commonParagraph: CommonParagraph
    public monacoEditorStore: MonacoEditorStore
    public deedResearchStore: DeedResearchStore
    public deedFilingStore: DeedFilingStore
    public matterStore: MatterStore
    public settingStore: SettingStore
    public errorLogStore: ErrorLogStore
    public emailLogStore: EmailLogStore
    public lawmaticsStore: LawmaticsStore
    public debugStore: DebugStore
    public trusteeInfoStore: TrusteeInfoStore
    public invoiceInfoStore: InvoiceStore
    public planStore: PlanStore
    public problemQuestionStore: ProblemQuestionStore
    public estateProtectPlanStore: EstateProtectPlanStore
    public visionStore: VisionStore
    public planPricingTemplateStore: PlanPricingTemplateStore
    public engagementTemplateStore: EngagementTemplateStore
    public thirdPartyLogStore: ThirdPartyLogStore
    public tagStore: TagStore
    public probableStore: ProbableStore
    public stateSpecificHCTempStore: StateSpecificHCTempStore
    public audioStore: AudioStore
    public allTemplatesStore: AllTemplatesStore
    public allUsersStore: AllUsersStore
    public allStatesStore: AllStatesStore
    public allFieldsStore: AllFieldsStore
    public allPackagesStore: AllPackagesStore
    public allConditionsStore: AllConditionsStore
    public allTagStore: AllTagStore
    public allEngagementTemplateStore: AllEngagementTemplateStore
    public allPlanPricingTemplateStore: AllPlanPricingTemplateStore
    public iPadLocalDataStore: IpadLocalDataStore
    public clientDraftAdjustmentStore: ClientDraftAdjustmentStore
    public singleDocDeedImagesStore: SingleDocDeedImagesStore
    public organizationStore: OrganizationStore
    public addressStore: AddressStore
    public allAddressStore: AllAddressStore
    public allOrgStore: AllOrgStore
    public packagePlanStore: PackagePlanStore
    public templateSpecificPDFStyleStore: TemplateSpecificPDFStyleStore

    public isAppLoading = true

    constructor() {
        this.authStore = new AuthStore(this)
        this.navStore = new NavStore(this)
        this.allStatesStore = new AllStatesStore(this)
        this.appUserStore = new AppUserStore(this)
        this.superAdminStore = new SuperAdminStore(this)
        this.stateStore = new StateStore(this)
        this.fieldStore = new FieldStore(this)
        this.templateStore = new TemplateStore(this)
        this.packageStore = new PackageStore(this)
        this.clientStore = new ClientStore(this)
        this.templateFieldStore = new TemplateFieldStore(this)
        this.pluralStore = new PluralStore(this)
        this.conditionStore = new ConditionStore(this)
        this.commonParagraph = new CommonParagraph(this)
        this.allFieldsStore = new AllFieldsStore(this)
        this.monacoEditorStore = new MonacoEditorStore(this)
        this.deedResearchStore = new DeedResearchStore(this)
        this.matterStore = new MatterStore(this)
        this.settingStore = new SettingStore(this)
        this.errorLogStore = new ErrorLogStore(this)
        this.emailLogStore = new EmailLogStore(this)
        this.lawmaticsStore = new LawmaticsStore(this)
        this.debugStore = new DebugStore(this)
        this.trusteeInfoStore = new TrusteeInfoStore(this)
        this.invoiceInfoStore = new InvoiceStore(this)
        this.planStore = new PlanStore(this)
        this.problemQuestionStore = new ProblemQuestionStore(this)
        this.estateProtectPlanStore = new EstateProtectPlanStore(this)
        this.visionStore = new VisionStore(this)
        this.probableStore = new ProbableStore(this)
        this.deedFilingStore = new DeedFilingStore(this)
        this.planPricingTemplateStore = new PlanPricingTemplateStore(this)
        this.engagementTemplateStore = new EngagementTemplateStore(this)
        this.thirdPartyLogStore = new ThirdPartyLogStore(this)
        this.tagStore = new TagStore(this)
        this.stateSpecificHCTempStore = new StateSpecificHCTempStore(this)
        this.audioStore = new AudioStore(this)
        this.allTemplatesStore = new AllTemplatesStore(this)
        this.allPackagesStore = new AllPackagesStore(this)
        this.allConditionsStore = new AllConditionsStore(this)
        this.allTagStore = new AllTagStore(this)
        this.allEngagementTemplateStore = new AllEngagementTemplateStore(this)
        this.allPlanPricingTemplateStore = new AllPlanPricingTemplateStore(this)
        this.clientDraftAdjustmentStore = new ClientDraftAdjustmentStore(this)
        this.allUsersStore = new AllUsersStore(this)
        this.iPadLocalDataStore = new IpadLocalDataStore(this)
        this.singleDocDeedImagesStore = new SingleDocDeedImagesStore(this)
        this.organizationStore = new OrganizationStore(this)
        this.addressStore = new AddressStore(this)
        this.allAddressStore = new AllAddressStore(this)
        this.allOrgStore = new AllOrgStore(this)
        this.packagePlanStore = new PackagePlanStore(this)
        this.templateSpecificPDFStyleStore = new TemplateSpecificPDFStyleStore(this)
        this.isAppLoading = false
    }

    /**
     * set Dom document title
     * @param title string
     */
    setTitle(title: string) {
        document.title = title
    }

    @action resetAllStore() {
        this.authStore.resetData()
        this.navStore.resetData()
        this.appUserStore.resetData()
        this.superAdminStore.resetData()
        this.stateStore.resetData()
        this.fieldStore.resetData()
        this.templateStore.resetData()
        this.packageStore.resetData()
        this.clientStore.resetData()
        this.templateFieldStore.resetData()
        this.pluralStore.resetData()
        this.conditionStore.resetData()
        this.commonParagraph.resetData()
        this.allFieldsStore.resetData()
        this.monacoEditorStore.resetData()
        this.deedResearchStore.resetData()
        this.matterStore.resetData()
        this.settingStore.resetData()
        this.errorLogStore.resetData()
        this.emailLogStore.resetData()
        this.lawmaticsStore.resetData()
        this.trusteeInfoStore.resetData()
        this.invoiceInfoStore.resetData()
        this.planStore.resetData()
        this.problemQuestionStore.resetData()
        this.estateProtectPlanStore.resetData()
        this.visionStore.resetData()
        this.probableStore.resetData()
        this.deedFilingStore.resetData()
        this.planPricingTemplateStore.resetData()
        this.engagementTemplateStore.resetData()
        this.thirdPartyLogStore.resetData()
        this.tagStore.resetData()
        this.stateSpecificHCTempStore.resetData()
        this.audioStore.resetData()
        this.allTemplatesStore.resetData()
        this.allPackagesStore.resetData()
        this.allConditionsStore.resetData()
        this.allTagStore.resetData()
        this.allEngagementTemplateStore.resetData()
        this.allPlanPricingTemplateStore.resetData()
        this.clientDraftAdjustmentStore.resetData()
        this.allUsersStore.resetData()
        this.iPadLocalDataStore.resetData()
        this.singleDocDeedImagesStore.resetData()
        this.organizationStore.resetData()
        this.addressStore.resetData()
        this.allAddressStore.resetData()
        this.allOrgStore.resetData()
        this.deedResearchStore.resetData()
    }
}

export default RootStore