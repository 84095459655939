import React from 'react'
import TimeAgo from 'react-timeago'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { englishStrings } from '../lawmatics/EnglishString'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { dateFormatWithTime } from './commonFuction'
import moment from 'moment'

interface Props {
    date: string,
    id: string
}

export const ShowLastUpdateDate: React.FC<Props> = ({ date, id }) => {
    const formatter = buildFormatter(englishStrings)
    const currentDate = moment()
    const after2MonthMomentDate = currentDate.subtract(2, 'months')
    const dateWithFormat = moment(date)

    return <>
        <span className='small-font' id={id}>
            (Last Updated: {after2MonthMomentDate.isBefore(dateWithFormat) ? <TimeAgo title="" date={date} formatter={formatter}/> : <>{dateFormatWithTime(date)} UTC</>})
        </span>
        { after2MonthMomentDate.isBefore(dateWithFormat) && <ReactTooltip place='top' anchorId={id}>
            <span className='z-3'>{dateFormatWithTime(date)} UTC</span>
        </ReactTooltip> }
    </>
}
