import React, { useState } from 'react'
import { ValueLabel, Dictionary, StateSelectable } from '../../store/types.d'
import FormModal from './FormModal'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { makeValueLabelList } from './commonFuction'
import classNames from 'classnames'
import { useHistory, useParams } from 'react-router-dom'
import TemplateStore from '../../store/Template'
import { toast } from 'react-toastify'

interface Props {
  options: Array<ValueLabel>
  targetStore?: StateSelectable
  selectorShow: boolean
  openCloseStateModal: () => void
  templateStore?: TemplateStore
  isLoading?: boolean
}

const StateSelector: React.FC<Props> = (props) => {
    const history = useHistory()
    const isMatterDocument = (history.location.pathname.includes('/clients/')) ? true : false
    const selected = makeValueLabelList(props.targetStore?.states, props.options)
    const [isAllState, setIsAllState] = useState(props.targetStore?.is_all_states)

    const scrollableBodyStyle = 'modal-body resource'
    const { register, handleSubmit, errors, control, setValue } = useForm()

    const { matterId } = useParams<{
            matterId: string
        }>()

    const onSaveModal = async (data: Dictionary<string>) => {
        const states = (data.states) ? data.states.map((item) => item.value) : []
        if (props.targetStore && props.templateStore) {
            props.targetStore.is_all_states = data.is_all_states
            props.targetStore.states = states
            if (isMatterDocument === true) {
                const response = await props.templateStore.updateTemplate(true, matterId)
                if(response.success === 1) {
                    toast.success(response.message)
                } else {
                    toast.error(response.message)
                }
                props.openCloseStateModal()
            } else {
                const response = await props.templateStore.updateTemplate(false, '')
                if(response.success === 1) {
                    toast.success(response.message)
                } else {
                    toast.error(response.message)
                }
                props.openCloseStateModal()
            }
        } else {
            props.openCloseStateModal()
        }
    }

    const handleAllStateSelect = (e: any) => {
        const value = e.target.checked
        setIsAllState(value)
        if (value) {
            setValue('states', [])
        }
    }

    return <>
        <FormModal show={props.selectorShow} modalTitle='Select States' openCloseModal={props.openCloseStateModal}>

            <form onSubmit={handleSubmit(onSaveModal)} >
                <div className={scrollableBodyStyle} >
                    <div className='ml-2'>
                        <div className="custom-control form-group mt-1 custom-switch">
                            <input type="checkbox" defaultChecked={isAllState}
                                ref={register}
                                className="custom-control-input" name='is_all_states' onClick={handleAllStateSelect} id="allState" />
                            <label className="custom-control-label" htmlFor='allState'>All States</label>
                            {errors.is_all_states && (<div className='invalid-feedback'>{errors.is_all_states.message}</div>)}
                        </div>
                        <div className='form-group'>
                            <div className={classNames({ 'd-none': isAllState })}>
                                <Controller
                                    control={control}
                                    name="states"
                                    defaultValue={selected}
                                    render={({ onChange, ref, value }) => (
                                        <Select
                                            isMulti
                                            inputRef={ref}
                                            value={value}
                                            options={props.options}
                                            className={(errors.states) ? 'is-invalid' : ''}
                                            classNamePrefix="select"
                                            // minLength={1}
                                            onChange={val => {
                                                onChange(val)
                                            }}
                                        />)}
                                    rules={{
                                        validate: (value) => (value.length === 0 && isAllState === false) ? 'Please select states' : true
                                    }}
                                />
                                {(errors.states) && (<div className='invalid-feedback'>Please select states</div>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='modal-footer'>
                    <button type="button" className="btn btn-outline-secondary" disabled={props.isLoading} data-dismiss="modal" onClick={props.openCloseStateModal}>Cancel</button>
                    <button type="submit" className="btn btn-outline-primary" disabled={props.isLoading}>Update</button>
                </div>

            </form>
        </FormModal>
    </>
}

export default StateSelector